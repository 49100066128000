.lightMode{
    /*
        --- Default style parameters ---
        Visit below URL for choosing color formats:
        https://colorhunt.co/palettes/spring
    */
    $backgroundColor: #fff;
    $backgroundColor2:#EEEFEF;
    $borderColor:#dddddd;
    $borderColor2:#dddddd;
    $textColor: #5B6A71;
    $gapStd:15px;
    $color1: #5D16B2;
    $color2: #8016B2;
    $color1: #4B0C95;
    $color2: #66148C;
    $color3: #F3BCC8;
    $color4: #F6FFA6;
    $delay:0.2s;
    text-align: center;
    //$color1: #152236;
    //$color2: #1D314F;


    background: $backgroundColor;
    color: $textColor;
    font-family: 'default', sans-serif;

    //input {width:200px;border-radius:0px;padding:10px;text-decoration: none; border:0px solid $borderColor2;font-size: 15px;outline: none;background: lighten($borderColor2,5);color:$textColor;}
    //input:focus {text-decoration: none;border:0px solid lighten($color2,30);background: darken($borderColor2,2);}

    .error {color:RED;margin: 2px 0px 7px;font-size: small;}
    .stdBackground {background: #f9f9f9;box-shadow: 0 0px 5px 0 $borderColor, 0 1px 8px 0 $borderColor;border-radius: 10px;margin: 0px 10px 10px;padding: 15px;}
    .padTop {padding-top: 15px;}
    .stdBorder {border:1px solid $borderColor;}
    .stdBorderBottomDashed {border:1px dashed $borderColor;border-left: 0px;border-right: 0px;border-top: 0px;}
    .stdBorderSelected {border:0px solid linear-gradient(#199119,#199119);background: linear-gradient(#2AA82B,#3DC33E);color:#ffffff;}

    .sidenavBodyMin {height:100vh;width: 70px; transition:$delay;color: $backgroundColor}
    .sidenavBodyMax {height:100vh;width: 190px; transition:$delay;color: $backgroundColor}
    .mainbodyMax {height:100vh;width: calc(100% - 70px);border: 0px dashed RED;transition:$delay;display: flex;align-items: stretch;}
    .mainbodyMin {height:100vh;width: calc(100% - 190px);border: 0px dashed RED;transition:$delay;display: flex;align-items: stretch;}

    // Sidenav
    .sidenavBodyMin{background: linear-gradient(0deg, $color1,$color2); color:$backgroundColor;}
    .sidenavBodyMax{background: linear-gradient(0deg, $color1, $color2);}
    

    // Sidenav > Buttons
    .button {border:0px dashed WHITE; display:flex; cursor: pointer;}
    .button:hover {background: #0000002a;}
    .buttonhighlight {background: #00000045;display:flex; cursor: pointer;}
    .buttonIcon {border:0px dashed WHITE;width:68px;height:53px;display: flex;justify-content: center;align-items: center;font-size:28px;color:#ffffffe7}
    .buttonNames {border:0px dashed WHITE;width:130px;height:50px;left:68px;display: flex; justify-content: flex-start;align-items: center;position:absolute;text-align: left;}
    .buttonName {width:130px;height:50px;display: flex; justify-content: flex-start;align-items: center;position:relative;text-align: left;color:#ffffffc8}
    .sidenavSeparation {margin-top:0px;height:calc(100vh - 100px);overflow-Y:auto;}
    
    // Mainbody
    $listingsWidth: 250px;
    .mainbody {background: $backgroundColor2;width: calc(100% - $listingsWidth);transition: $delay;box-shadow: 0 0px 10px 0 $borderColor, 0 1px 12px 0 $borderColor;display:'flex';align-items: 'center';margin:'auto 0px auto'}
    .mainbodyToggle {background: $backgroundColor2;width: calc(100% - 0px);transition: $delay;display:'flex';align-items: 'center';margin:'auto 0px auto'}
    // Mainbody > Listings
    .listingBodyOn {width:$listingsWidth;height:100vh;background:$backgroundColor;transition: $delay;}
    .listingBodyOff {width:0px;height:100vh;background:$backgroundColor;transition: $delay;}
    // Mainbody > Title
    .mainTitle {height:60px;background: $backgroundColor;}
    .mainTitleIcons {border:0px dashed #ccc;width:fit-content;display:flex;align-items:center;margin:auto 0px auto;height:60px;}
    .mainTitleButtons {border:0px dashed #ccc;border-bottom:5px solid $backgroundColor;width:fit-content;display:flex;align-items:center;margin:auto 0px auto;height:50px;margin-left:0px;transition: $delay;padding:5px 10px 0px;color:lighten($textColor,20)}
    .mainTitleButtons:hover {border-bottom:5px solid $borderColor;cursor: pointer;transition: $delay;}
    .mainTitleButtonsSelected {border:0px dashed #ccc;border-bottom:5px solid lighten($color2,20);width:fit-content;display:flex;align-items:center;margin:auto 0px auto;height:50px;margin-left:0px;transition: $delay;padding:5px 10px 0px;cursor: pointer;}
    .mainTitleIconsHolder {margin: 0px 7px 0px 15px;width:25px;height:25px;border-radius: 5px;
        background: linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71));
        box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(76, 175, 79, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
        color:WHITE;padding:6px;display:flex;justify-content: center;}
    .mainTitleExtention {width:150px; height:auto;background: $backgroundColor;border: 1px solid $borderColor;position:absolute;top:58px;display: block;}
    .mainTitleExtentionDisable {display: none;}
    .mainTitleExtentionButtons {padding: $gapStd calc(1*$gapStd) $gapStd;text-align: left;}
    .mainTitleExtentionButtons:hover {color:$textColor}


    /* Hide the scrollbar by default */
    .scrollbarType1 {
        overflow: auto;
        transition: $delay;
    }
    
    /* Styling for the scrollbar */
    .scrollbarType1::-webkit-scrollbar {
        width: 10px;
        background-color: transparent; /* Set the background color to transparent */
        transition: $delay;
    }
    
    .scrollbarType1::-webkit-scrollbar-thumb {
        background-color: #ffffff2a;
        border-radius: 4px;
        transition: $delay;
    }
    
    /* Show the scrollbar on hover */
    .scrollbarType1:hover::-webkit-scrollbar-thumb {
        background-color: #ffffff6f;
        transition: $delay;
    }
    
    .scrollbarType1:hover {
        overflow: auto;
        transition: $delay;
    }

    .stdbox {
        background: linear-gradient(195deg, #fff, #fff);
        box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(64, 64, 64, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
        padding:10px 20px 10px;border-radius: 10px;color:#fff;
        color:$textColor;
    }
    .stdbox_2 {
        background: linear-gradient(195deg, #fff, #fff);
        padding:10px 20px 10px;border-radius: 10px;color:#fff;
        color:$textColor;
    }
    .stdbox_3 {
        background: linear-gradient(195deg, #fff, #fff);
        //box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(64, 64, 64, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
        padding:10px 20px 10px;border-radius: 0px;color:#fff;
        color:$textColor;
    }
    .stdbox_red{
        background:linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96));
        box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(76, 175, 79, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
        color:#fff;
    }
    .stdbox_red_2{
        background:linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96));
        box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(76, 175, 79, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
        color:#fff;
    }
    .stdbox_red_2:hover{
        background:lighten($color: rgb(216, 27, 96), $amount: 10);cursor: pointer;
    }
    .stdbox_black{
        background:linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25));
        box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(64, 64, 64, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
        color:#fff;
    }
    .stdbox_black_3{
        background:linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25));
        color:#fff;
    }
    .stdbox_green{
        background:linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71));
        box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(76, 175, 79, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
        color:#fff;
    }
    .stdbox_green_2{
        background:linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71));
        box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(76, 175, 79, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
        color:#fff;
    }
    .stdbox_green_2:hover{
        background:lighten($color: rgb(67, 160, 71), $amount: 10);cursor: pointer;
    }
    .stdbox_blue{
        background:linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
        box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(76, 175, 79, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
        color:#fff;
    }
    .stdListingItem{
        display:flex;border-Top:0px;border-left:0px;border-Right:0px;
    }
    .stdMiddle{
        display: flex;margin: auto 0px auto;
    }
    .stdbgHighlight{background: #efefef;cursor: pointer;}
    .stdbgList:hover{background: #efefef;cursor: pointer;}
    .list_item{border-bottom: 1px solid $borderColor;width:100%;height:75px;display: flex;transition: $delay;}
    .list_item_selected{background-color: darken($color: $backgroundColor, $amount: 15);border-bottom: 1px solid $borderColor;width:100%;height:75px;display: flex;transition: $delay;}
    .list_item:hover{cursor: pointer;background-color: darken($color: $backgroundColor, $amount: 7);transition: $delay;}
    .order_bigbox{border:5px solid #ddd;margin:20px 10px;height:200px;width:200px;border-radius: 20px;}
    .stdcolorblur{color:lighten($textColor,20)}
    .stdcolortheme{color:$color2}
    .stdclose{color:lighten($textColor,30)}
    .stdclose:hover{color:lighten($textColor,20);cursor: pointer;}
    .logo {font-family: "logo";font-size: 100px;border-radius: 30px;
        background:linear-gradient(195deg, rgb(248, 248, 248), rgb(255, 255, 255));
        box-shadow: rgba(81, 1, 73, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(61, 3, 57, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
        display: flex;justify-content: center;text-align: center;margin: auto;padding:20px 20px 10px 10px;width:180px;height:150px;
        font-size: 180px; color:$textColor;
    }
    .bgTheme {
        background: darken($color1,15);
        box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(64, 64, 64, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
        color:#fff;
    }
    

}